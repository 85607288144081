import { useProgress } from '@react-three/drei';
import { useLenis } from '@studio-freight/react-lenis';
import { useEffect, useState } from 'react';
import LoaderSvgIconFirst from '../LoaderSvgIconFirst';
import LoaderSvgIconSecond from '../LoaderSvgIconSecond';
import LoaderSvgIconThird from '../LoaderSvgIconThird';
import './style.scss';

const LoaderScreen = () => {
  const { progress } = useProgress();
  const [loaded, setLoaded] = useState(false);
  const lenis = useLenis();

  useEffect(() => {
    if (lenis) {
      lenis.stop();
    }

    if (progress === 100) {
      setTimeout(() => {
        setLoaded(true);
        if (lenis) {
          lenis.start();
        }
      }, 6000);
    }
  }, [progress, lenis]);

  return (
    <div className={`loader ${loaded ? 'loader-hidden' : ''}`}>
      <div className='loader-icon'>
        <LoaderSvgIconSecond />
        <LoaderSvgIconFirst />
        <LoaderSvgIconThird />
      </div>
      <div className='loader-text'>
        <span>{Math.round(progress)}%</span>
      </div>
    </div>
  );
};

export default LoaderScreen;
