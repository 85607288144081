import { useEffect, useState } from 'react';
import './style.scss';

const Header = () => {
  useEffect(() => {
    const burger = document.querySelector('.burger');
    const headerNav = document.querySelector('.header-nav');

    document.querySelectorAll('a[href^="#"]').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        const id = el.getAttribute('href')?.slice(1);
        if (!id) return;
        const target = document.getElementById(id);
        if (target) {
          if (window.innerWidth > 768) {
            target.scrollIntoView({ behavior: 'smooth' });
          } else {
            target.scrollIntoView({ behavior: 'auto' });
          }

          headerNav.classList.remove('menu-active');
          burger.classList.remove('burger-active');
        }
      });
    });
    const handleBurgerClick = () => {
      headerNav.classList.toggle('menu-active');
      burger.classList.toggle('burger-active');
    };

    const handleOutsideClick = (event) => {
      if (!headerNav.contains(event.target) && !burger.contains(event.target)) {
        headerNav.classList.remove('menu-active');
        burger.classList.remove('burger-active');
      }
    };

    burger.addEventListener('click', handleBurgerClick);
    document.addEventListener('click', handleOutsideClick);

    return () => {
      burger.removeEventListener('click', handleBurgerClick);
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const [activeLink, setActiveLink] = useState('');
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const sections = document.querySelectorAll('section[id]');
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition >= sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveLink(`#${section.id}`);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollDirection = scrollPosition > lastScrollY ? 'down' : 'up';

      if (scrollDirection === 'down' && scrollPosition > 100) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }

      setLastScrollY(scrollPosition);

      const sections = document.querySelectorAll('section[id]');
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition >= sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveLink(`#${section.id}`);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <header className={`header ${isVisible ? '' : 'header-hidden'}`}>
      <div className='container'>
        <div className='header__wrapper'>
          <a
            className='header-logo'
            href='/'>
            <img
              src='./img/logo.svg'
              alt=''
            />
          </a>
          <div className='header-nav'>
            <nav className='menu'>
              <ul>
                <li>
                  <a
                    className={activeLink === '#research' ? 'active-link' : ''}
                    href='#research'>
                    Дослідження
                  </a>
                </li>
                <li>
                  <a
                    className={activeLink === '#identity' ? 'active-link' : ''}
                    href='#identity'>
                    Айдентика
                  </a>
                </li>
                <li>
                  <a
                    className={activeLink === '#team' ? 'active-link' : ''}
                    href='#team'>
                    Команда
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className='burger'></div>
        </div>
      </div>
    </header>
  );
};

export default Header;
