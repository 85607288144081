import './style.scss';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

let characterBlocks = [
  {
    img: './img/character/character1.jpg',
    title: 'Спокійний і розмірений',
    text: 'Не любить поспіху та метушні. Цінує можливість насолоджуватися кожною миттю життя, знаходити час для рефлексії та роздумів.',
  },
  {
    img: './img/character/character2.jpg',
    title: 'Дружній і приязний',
    text: 'Легко знаходить спільну мову з різними людьми. Гостинний та привітний у відносинах.',
  },
  {
    img: './img/character/character3.jpg',
    title: 'Допитливий і креативний',
    text: 'Любить створювати та відкривати для себе щось нове. Захоплюється мистецтвом, літературою, цікавиться інноваційними ідеями.',
  },
  {
    img: './img/character/character4.jpg',
    title: 'Гнучкий і адаптивний',
    text: 'Легко пристосовується до змін, відкритий до нових ідей та поглядів. Водночас дбайливо ставиться до традицій і спадщини минулого.',
  },
];

const Character = () => {
  useEffect(() => {
    gsap.to('.character-block', {
      scrollTrigger: {
        trigger: '.character',
        start: '-25% 0',
      },
      y: 0,
      opacity: 1,
      stagger: 0.3,
      duration: 1.3,
    });
  }, []);
  return (
    <section
      className='character'
      id='character'>
      <div className='container'>
        <h2>Характер міста</h2>
        <div className='character__wrapper'>
          {characterBlocks.map((item, index) => (
            <div
              className='character-block'
              key={index}>
              <img
                src={item.img}
                alt=''
              />
              <h3>{item.title}</h3>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Character;
