import './style.scss';

import React, { useEffect, useRef, useCallback } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useState } from 'react';

gsap.registerPlugin(ScrollTrigger);

const images = [
  {
    src: './img/symbols/1.jpg',
    text: 'Чернівецький університет',
    alt: 'Чернівецький університет',
  },
  {
    src: './img/symbols/2.jpg',
    text: 'Ратуша',
    alt: 'Ратуша',
  },
  {
    src: './img/symbols/3.jpg',
    text: 'Театр ім. О.Кобилянської',
    alt: 'Театр ім. О.Кобилянської',
  },
  {
    src: './img/symbols/4.jpg',
    text: 'Червона Рута - 1989',
    alt: 'Червона Рута - 1989',
  },
  {
    src: './img/symbols/5.jpg',
    text: 'Сурмач на ратуші',
    alt: 'Сурмач на ратуші',
  },

  {
    src: './img/symbols/8.jpg',
    text: 'Легенда про троянди',
    alt: 'Легенда про троянди',
  },
];

const Symbols = () => {
  const wheelRef = useRef(null);
  const imagesRef = useRef([]);
  const [radiusSlider, setRadiusSlider] = useState(
    window.innerWidth < 550 ? 0.5 : 1.4
  );

  useEffect(() => {
    if (
      (window.innerWidth < 770 && window.innerHeight > 1000) ||
      window.innerHeight < 450
    ) {
      return;
    } else {
      const setup = () => {
        const wheel = wheelRef.current;
        if (!wheel) return;

        let totalOne = 0;

        if (window.innerWidth < 550) {
          totalOne = 3.5;
        }

        const radius = wheel.offsetWidth / radiusSlider;
        const center = wheel.offsetWidth / 2;
        const total = imagesRef.current.length + totalOne;
        const slice = (2 * Math.PI) / total;

        imagesRef.current.forEach((item, i) => {
          if (!item) return;

          const angle = i * slice;
          const x = center + radius * Math.sin(angle);
          const y = center - radius * Math.cos(angle);

          gsap.set(item, {
            rotation: `${angle}_rad`,
            xPercent: -50,
            yPercent: -50,
            x,
            y,
          });
        });
      };

      setup();

      let topTriggerPosition = 100;

      let prev = document.querySelector('.prev');

      if (window.innerWidth < 1100) {
        topTriggerPosition = 100;
      }

      if (
        window.innerWidth < 1100 &&
        window.innerWidth > 991 &&
        window.innerHeight > 599
      ) {
        topTriggerPosition = 0;
      }

      const scrollTween = gsap.to(wheelRef.current, {
        rotate: () => (window.innerWidth < 550 ? -189.5 : -300),
        ease: 'none',
        duration: 1,
        scrollTrigger: {
          trigger: '.symbols',
          start: () => `top ${prev.clientHeight + topTriggerPosition}`,
          end: () => (window.innerWidth < 600 ? '+=1500' : '+=3500'),
          scrub: 2,
          pin: '.symbols__wrapper',
          invalidateOnRefresh: true,
        },
      });

      window.addEventListener('resize', () => {
        ScrollTrigger.refresh();
      });

      return () => {
        scrollTween.scrollTrigger.kill();
      };
    }
  }, [radiusSlider, imagesRef.current.length]);

  return (
    <>
      <h2 className='symbols-title'>Ключові символи міста</h2>
      <h3 className='symbols-subtitle'>
        Символи Чернівців – це те, що вирізняє місто та робить його унікальним
        як для місцевих мешканців, так і для гостей. У ході дослідження ми
        виділили головні символи Чернівців, які відображають його багату
        історію, архітектурну спадщину та культурне розмаїття.
      </h3>
      <div className='prev'></div>
      <section
        className='symbols'
        id='brand-strategy'>
        <div className='symbols__wrapper'>
          {(window.innerWidth < 770 && window.innerHeight > 1000) ||
          window.innerHeight < 450 ? (
            <div className='slide-section'>
              <div
                className='slide-wrap'
                ref={wheelRef}>
                {images.map((src, index) => (
                  <div
                    className='slide-card'
                    ref={(el) => (imagesRef.current[index] = el)}
                    key={index}>
                    <img
                      src={src.src}
                      alt={`img-${index}`}
                    />
                    <p>{src.text}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className='slider-section'>
              <div
                className='wheel'
                ref={wheelRef}>
                {images.map((src, index) => (
                  <div
                    className='wheel__card'
                    ref={(el) => (imagesRef.current[index] = el)}
                    key={index}>
                    <img
                      src={src.src}
                      alt={`img-${index}`}
                    />
                    <p>{src.text}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Symbols;
