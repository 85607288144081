import React, { useEffect } from 'react';
import gsap from 'gsap';

const LoaderSvgIconThird = () => {
  useEffect(() => {
    const elements = document.querySelectorAll('svg .st11, svg .st16');

    gsap.to('.loader-icon-third', {
      opacity: 1,
      delay: 5.3,
    });

    elements.forEach((element) => {
      const length = element.getTotalLength ? element.getTotalLength() : 0;

      if (length) {
        gsap.fromTo(
          element,
          { strokeDasharray: length, strokeDashoffset: length },
          {
            strokeDasharray: length,
            strokeDashoffset: 0,
            duration: 1.5,
            delay: 5.3,
            ease: 'power1.inOut',
          }
        );
      }
    });
  }, []);

  return (
    <svg
      className='loader-icon-third'
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      id='Слой_1'
      x='0px'
      y='0px'
      viewBox='0 0 500 500'
      style={{ enableBackground: 'new 0 0 500 500', opacity: '0' }}>
      <style type='text/css'>
        {`
          .st0{fill:#23170B;}
          .st1{fill:#FFFFFF;}
          .st2{fill:#ADADAD;}
          .st3{fill:none;stroke:#DF3C17;stroke-width:14;stroke-miterlimit:10;}
          .st4{fill:none;stroke:#D0C8A2;stroke-width:14;stroke-miterlimit:10;}
          .st5{fill:none;stroke:#158FCA;stroke-width:14;stroke-miterlimit:10;}
          .st6{fill:none;stroke:#B3C5C9;stroke-width:14;stroke-miterlimit:10;}
          .st7{fill:none;stroke:#E7AE27;stroke-width:14;stroke-miterlimit:10;}
          .st8{fill:none;stroke:#E98A50;stroke-width:14;stroke-miterlimit:10;}
          .st9{fill:none;stroke:#406EB0;stroke-width:14;stroke-miterlimit:10;}
          .st10{fill:#E98A50;}
          .st11{fill:none;stroke:#00882F;stroke-width:14;stroke-miterlimit:10;}
          .st12{fill:none;stroke:#00882F;stroke-width:13.8262;stroke-miterlimit:10;}
          .st13{fill:none;stroke:#00882F;stroke-width:12.6097;stroke-miterlimit:10;}
          .st14{fill:#DF3C17;}
          .st15{fill:none;stroke:#00882F;stroke-width:4;stroke-miterlimit:10;}
          .st16{fill:#00882F;}
          .st17{fill:none;stroke:#E7AE27;stroke-width:14.0506;stroke-miterlimit:10;}
          .st18{fill:none;stroke:#B3C5C9;stroke-width:12.1121;stroke-miterlimit:10;}
          .st19{fill:none;stroke:#E2001A;stroke-width:4;stroke-miterlimit:10;}
          .st20{fill:none;stroke:#DFA754;stroke-width:14;stroke-miterlimit:10;}
          .st21{fill:#DFA754;}
        `}
      </style>
      <g>
        <g>
          <polygon
            className='st11'
            points='305.1,250 344,289 289,289 289,344 250,305.1 211,344 211,289 156,289 194.9,250 156,211 211,211 211,156 250,194.9 289,156 289,211 344,211'
          />
          <line
            className='st11'
            x1='194.9'
            y1='250'
            x2='305.1'
            y2='250'
          />
          <line
            className='st11'
            x1='250'
            y1='194.9'
            x2='250'
            y2='305.1'
          />
        </g>
        <g>
          <path
            className='st11'
            d='M230.5,175.4l17.3-69.4c0.6-2.3,3.8-2.3,4.4,0l17.3,69.4'
          />
          <circle
            className='st16'
            cx='250'
            cy='81.4'
            r='10.2'
          />
        </g>
        <g>
          <path
            className='st11'
            d='M183.5,211l-36.9-61.3c-1.2-2,1.1-4.3,3.1-3.1l61.3,36.9'
          />
          <circle
            className='st16'
            cx='130.7'
            cy='130.7'
            r='10.2'
          />
        </g>
        <g>
          <path
            className='st11'
            d='M175.4,269.5L106,252.2c-2.3-0.6-2.3-3.8,0-4.4l69.4-17.3'
          />
          <circle
            className='st16'
            cx='81.4'
            cy='250'
            r='10.2'
          />
        </g>
        <g>
          <path
            className='st11'
            d='M211,316.5l-61.3,36.9c-2,1.2-4.3-1.1-3.1-3.1l36.9-61.3'
          />
          <circle
            className='st16'
            cx='130.7'
            cy='369.3'
            r='10.2'
          />
        </g>
        <g>
          <path
            className='st11'
            d='M269.5,324.6L252.2,394c-0.6,2.3-3.8,2.3-4.4,0l-17.3-69.4'
          />
          <circle
            className='st16'
            cx='250'
            cy='418.6'
            r='10.2'
          />
        </g>
        <g>
          <path
            className='st11'
            d='M316.5,289l36.9,61.3c1.2,2-1.1,4.3-3.1,3.1L289,316.5'
          />
          <circle
            className='st16'
            cx='369.3'
            cy='369.3'
            r='10.2'
          />
        </g>
        <g>
          <path
            className='st11'
            d='M324.6,230.5l69.4,17.3c2.3,0.6,2.3,3.8,0,4.4l-69.4,17.3'
          />
          <circle
            className='st16'
            cx='418.6'
            cy='250'
            r='10.2'
          />
        </g>
        <g>
          <path
            className='st11'
            d='M289,183.5l61.3-36.9c2-1.2,4.3,1.1,3.1,3.1L316.5,211'
          />
          <circle
            className='st16'
            cx='369.3'
            cy='130.7'
            r='10.2'
          />
        </g>
      </g>
    </svg>
  );
};

export default LoaderSvgIconThird;
