import './style.scss';

const Footer = () => {
  return (
    <footer className='footer'>
      <img
        className='footer-icon'
        src='./img/download-svg.svg'
        alt=''
      />
      <div className='container'>
        <div className='footer__wrapper'>
          <div className='footer-copy'>
            © Copyright 2024 by OCB Agency. All Rights Reserved.
          </div>
          <ul className='footer-anchor'>
            <li>
              <a href='#research'>Дослідження</a>
            </li>

            <li>
              <a href='#identity'>Айдентика</a>
            </li>
            <li>
              <a href='#team'>Команда</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
