import React from 'react';
import { useEffect } from 'react';
import { gsap } from 'gsap';

const LoaderSvgIconFirst = () => {
  useEffect(() => {
    const elements = document.querySelectorAll(
      '.loader-icon-first g > polygon, .loader-icon-first g > line'
    );

    gsap.to('.loader-icon-first', {
      opacity: 1,
    });

    elements.forEach((element) => {
      const length = element.getTotalLength();

      gsap.fromTo(
        element,
        { strokeDasharray: length, strokeDashoffset: length },
        {
          strokeDasharray: length,
          strokeDashoffset: 0,
          duration: 1.5,

          ease: 'power1.inOut',
          onComplete: () => {
            gsap.to(element, {
              strokeDashoffset: length,
              duration: 1.5,
              delay: 0.5,
              ease: 'power1.inOut',
            });
          },
        }
      );
    });

    gsap.to('.loader-icon-first', {
      delay: 2,
      opacity: 0,
    });
  }, []);
  return (
    <>
      <svg
        className='loader-icon-first'
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        x='0px'
        y='0px'
        viewBox='0 0 500 500'
        style={{ enableBackground: 'new 0 0 500 500' }}>
        <style type='text/css'>{`
          .st0 { fill: #23170B; }
          .st1 { fill: #FFFFFF; }
          .st2 { fill: #ADADAD; }
          .st3 { fill: none; stroke: #DF3C17; stroke-width: 14; stroke-miterlimit: 10; }
          .st4 { fill: none; stroke: #D0C8A2; stroke-width: 14; stroke-miterlimit: 10; }
          .st5 { fill: none; stroke: #158FCA; stroke-width: 14; stroke-miterlimit: 10; }
          .st6 { fill: none; stroke: #B3C5C9; stroke-width: 14; stroke-miterlimit: 10; }
          .st7 { fill: none; stroke: #E7AE27; stroke-width: 14; stroke-miterlimit: 10; }
          .st8 { fill: none; stroke: #E98A50; stroke-width: 14; stroke-miterlimit: 10; }
          .st9 { fill: none; stroke: #406EB0; stroke-width: 14; stroke-miterlimit: 10; }
          .st10 { fill: #E98A50; }
          .st11 { fill: none; stroke: #00882F; stroke-width: 14; stroke-miterlimit: 10; }
          .st12 { fill: none; stroke: #00882F; stroke-width: 13.8262; stroke-miterlimit: 10; }
          .st13 { fill: none; stroke: #00882F; stroke-width: 12.6097; stroke-miterlimit: 10; }
          .st14 { fill: #DF3C17; }
          .st15 { fill: none; stroke: #00882F; stroke-width: 4; stroke-miterlimit: 10; }
          .st16 { fill: #00882F; }
          .st17 { fill: none; stroke: #E7AE27; stroke-width: 14.0506; stroke-miterlimit: 10; }
          .st18 { fill: none; stroke: #B3C5C9; stroke-width: 12.1121; stroke-miterlimit: 10; }
          .st19 { fill: none; stroke: #E2001A; stroke-width: 4; stroke-miterlimit: 10; }
          .st20 { fill: none; stroke: #DFA754; stroke-width: 14; stroke-miterlimit: 10; }
          .st21 { fill: #DFA754; }
        `}</style>
        <g>
          <polygon
            className='st3'
            points='277.7,129.9 289,129.9 289,156 250,194.9 211,156 211,129.9 222.3,129.9 222.3,111.3 235.7,111.3 235.7,93 264.3,93 264.3,111.3 277.7,111.3'
          />
          <polygon
            className='st3'
            points='184.6,145.5 192.6,137.5 211,156 211,211 155.9,211 137.5,192.6 145.5,184.6 132.4,171.5 141.8,162 128.9,149 149,128.9 162,141.8 171.5,132.4'
          />
          <polygon
            className='st3'
            points='129.9,222.3 129.9,211 156,211 194.9,250 156,289 129.9,289 129.9,277.7 111.3,277.7 111.3,264.3 93,264.3 93,235.7 111.3,235.7 111.3,222.3'
          />
          <polygon
            className='st3'
            points='145.5,315.4 137.5,307.4 156,289 211,289 211,344.1 192.6,362.5 184.6,354.5 171.5,367.6 162,358.2 149,371.1 128.9,351 141.8,338 132.4,328.5'
          />
          <polygon
            className='st3'
            points='222.3,370.1 211,370.1 211,344 250,305.1 289,344 289,370.1 277.7,370.1 277.7,388.7 264.3,388.7 264.3,407 235.7,407 235.7,388.7 222.3,388.7'
          />
          <polygon
            className='st3'
            points='315.4,354.5 307.4,362.5 289,344 289,289 344.1,289 362.5,307.4 354.5,315.4 367.6,328.5 358.2,338 371.1,351 351,371.1 338,358.2 328.5,367.6'
          />
          <polygon
            className='st3'
            points='370.1,277.7 370.1,289 344,289 305.1,250 344,211 370.1,211 370.1,222.3 388.7,222.3 388.7,235.7 407,235.7 407,264.3 388.7,264.3 388.7,277.7'
          />
          <polygon
            className='st3'
            points='354.5,184.6 362.5,192.6 344,211 289,211 289,155.9 307.4,137.5 315.4,145.5 328.5,132.4 338,141.8 351,128.9 371.1,149 358.2,162 367.6,171.5'
          />
          <polygon
            className='st3'
            points='305.1,250 344,288.9 289,288.9 289,344 250,305.1 211,344 211,288.9 156,288.9 194.9,250 156,211 211,211 211,156 250,194.9 289,156 289,211 344,211'
          />
          <line
            className='st3'
            x1='194.9'
            y1='250'
            x2='305.1'
            y2='250'
          />
          <line
            className='st3'
            x1='250'
            y1='305.1'
            x2='250'
            y2='194.9'
          />
        </g>
      </svg>
    </>
  );
};

export default LoaderSvgIconFirst;
