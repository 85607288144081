import React, { useEffect } from 'react';
import gsap from 'gsap';

const LoaderSvgIconSecond = () => {
  useEffect(() => {
    const elements = document.querySelectorAll('svg .st7, svg .st17');

    gsap.to('.loader-icon-second', {
      opacity: 1,
      delay: 2.3,
    });

    elements.forEach((element) => {
      const length = element.getTotalLength();

      gsap.fromTo(
        element,
        { strokeDasharray: length, strokeDashoffset: length },
        {
          strokeDasharray: length,
          strokeDashoffset: 0,
          duration: 1.5,
          delay: 2.3,
          ease: 'power1.inOut',
          onComplete: () => {
            gsap.to(element, {
              strokeDashoffset: length,
              duration: 1.5,
              delay: 1,
              ease: 'power1.inOut',
            });
          },
        }
      );
    });

    gsap.to('.loader-icon-second', {
      opacity: 0,
      delay: 5,
    });
  }, []);

  return (
    <svg
      className='loader-icon-second'
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      id='Слой_1'
      x='0px'
      y='0px'
      viewBox='0 0 500 500'
      style={{ enableBackground: 'new 0 0 500 500', opacity: '0' }}>
      <style type='text/css'>
        {`
          .st0{fill:#23170B;}
          .st1{fill:#FFFFFF;}
          .st2{fill:#ADADAD;}
          .st3{fill:none;stroke:#DF3C17;stroke-width:14;stroke-miterlimit:10;}
          .st4{fill:none;stroke:#D0C8A2;stroke-width:14;stroke-miterlimit:10;}
          .st5{fill:none;stroke:#158FCA;stroke-width:14;stroke-miterlimit:10;}
          .st6{fill:none;stroke:#B3C5C9;stroke-width:14;stroke-miterlimit:10;}
          .st7{fill:none;stroke:#E7AE27;stroke-width:14;stroke-miterlimit:10;}
          .st8{fill:none;stroke:#E98A50;stroke-width:14;stroke-miterlimit:10;}
          .st9{fill:none;stroke:#406EB0;stroke-width:14;stroke-miterlimit:10;}
          .st10{fill:#E98A50;}
          .st11{fill:none;stroke:#00882F;stroke-width:14;stroke-miterlimit:10;}
          .st12{fill:none;stroke:#00882F;stroke-width:13.8262;stroke-miterlimit:10;}
          .st13{fill:none;stroke:#00882F;stroke-width:12.6097;stroke-miterlimit:10;}
          .st14{fill:#DF3C17;}
          .st15{fill:none;stroke:#00882F;stroke-width:4;stroke-miterlimit:10;}
          .st16{fill:#00882F;}
          .st17{fill:none;stroke:#E7AE27;stroke-width:14.0506;stroke-miterlimit:10;}
          .st18{fill:none;stroke:#B3C5C9;stroke-width:12.1121;stroke-miterlimit:10;}
          .st19{fill:none;stroke:#E2001A;stroke-width:4;stroke-miterlimit:10;}
          .st20{fill:none;stroke:#DFA754;stroke-width:14;stroke-miterlimit:10;}
          .st21{fill:#DFA754;}
        `}
      </style>
      <g>
        <g>
          <polygon
            className='st7'
            points='305.1,250 344,289 289,289 289,344 250,305.1 211,344 211,289 156,289 194.9,250 156,211 211,211 211,156 250,194.9 289,156 289,211 344,211'
          />
          <line
            className='st7'
            x1='194.9'
            y1='250'
            x2='305.1'
            y2='250'
          />
          <line
            className='st7'
            x1='250'
            y1='194.9'
            x2='250'
            y2='305.1'
          />
        </g>
        <g>
          <path
            className='st17'
            d='M250,184.5v-48.7c0-10.8,8.7-19.5,19.5-19.5h0c10.8,0,19.5,8.7,19.5,19.5V156'
          />
          <path
            className='st7'
            d='M289,74.2L289,74.2c-21.5,0-39,17.4-39,39v78.8'
          />
          <path
            className='st7'
            d='M211,74.2L211,74.2c21.5,0,39,17.4,39,39v78.8'
          />
          <line
            className='st7'
            x1='211'
            y1='74.2'
            x2='289'
            y2='74.2'
          />
          <line
            className='st17'
            x1='289'
            y1='142.9'
            x2='225.2'
            y2='142.9'
          />
        </g>
        <g>
          <path
            className='st17'
            d='M203.7,203.7l-34.4-34.4c-7.6-7.6-7.6-19.9,0-27.5l0,0c7.6-7.6,19.9-7.6,27.5,0L211,156'
          />
          <path
            className='st7'
            d='M153.2,98.1L153.2,98.1c-15.2,15.2-15.2,39.9,0,55.1l55.7,55.7'
          />
          <path
            className='st7'
            d='M98.1,153.2L98.1,153.2c15.2-15.2,39.9-15.2,55.1,0l55.7,55.7'
          />
          <line
            className='st7'
            x1='98.1'
            y1='153.2'
            x2='153.2'
            y2='98.1'
          />
          <line
            className='st17'
            x1='201.8'
            y1='146.7'
            x2='156.7'
            y2='191.8'
          />
        </g>
        <g>
          <path
            className='st17'
            d='M184.5,250h-48.7c-10.8,0-19.5-8.7-19.5-19.5v0c0-10.8,8.7-19.5,19.5-19.5H156'
          />
          <path
            className='st7'
            d='M74.2,211L74.2,211c0,21.5,17.4,39,39,39h78.8'
          />
          <path
            className='st7'
            d='M74.2,289L74.2,289c0-21.5,17.4-39,39-39h78.8'
          />
          <line
            className='st7'
            x1='74.2'
            y1='289'
            x2='74.2'
            y2='211'
          />
          <line
            className='st17'
            x1='142.9'
            y1='211'
            x2='142.9'
            y2='274.8'
          />
        </g>
        <g>
          <path
            className='st17'
            d='M203.7,296.3l-34.4,34.4c-7.6,7.6-19.9,7.6-27.5,0l0,0c-7.6-7.6-7.6-19.9,0-27.5L156,289'
          />
          <path
            className='st7'
            d='M98.1,346.8L98.1,346.8c15.2,15.2,39.9,15.2,55.1,0l55.7-55.7'
          />
          <path
            className='st7'
            d='M153.2,401.9L153.2,401.9c-15.2-15.2-15.2-39.9,0-55.1l55.7-55.7'
          />
          <line
            className='st7'
            x1='153.2'
            y1='401.9'
            x2='98.1'
            y2='346.8'
          />
          <line
            className='st17'
            x1='146.7'
            y1='298.2'
            x2='191.8'
            y2='343.3'
          />
        </g>
        <g>
          <path
            className='st17'
            d='M250,315.5v48.7c0,10.8-8.7,19.5-19.5,19.5h0c-10.8,0-19.5-8.7-19.5-19.5V344'
          />
          <path
            className='st7'
            d='M211,425.8L211,425.8c21.5,0,39-17.4,39-39v-78.8'
          />
          <path
            className='st7'
            d='M289,425.8L289,425.8c-21.5,0-39-17.4-39-39v-78.8'
          />
          <line
            className='st7'
            x1='289'
            y1='425.8'
            x2='211'
            y2='425.8'
          />
          <line
            className='st17'
            x1='211'
            y1='357.1'
            x2='274.8'
            y2='357.1'
          />
        </g>
        <g>
          <path
            className='st17'
            d='M296.3,296.3l34.4,34.4c7.6,7.6,7.6,19.9,0,27.5h0c-7.6,7.6-19.9,7.6-27.5,0L289,344'
          />
          <path
            className='st7'
            d='M346.8,401.9L346.8,401.9c15.2-15.2,15.2-39.9,0-55.1l-55.7-55.7'
          />
          <path
            className='st7'
            d='M401.9,346.8L401.9,346.8c-15.2,15.2-39.9,15.2-55.1,0l-55.7-55.7'
          />
          <line
            className='st7'
            x1='401.9'
            y1='346.8'
            x2='346.8'
            y2='401.9'
          />
          <line
            className='st17'
            x1='298.2'
            y1='353.3'
            x2='343.3'
            y2='308.2'
          />
        </g>
        <g>
          <path
            className='st17'
            d='M315.5,250h48.7c10.8,0,19.5,8.7,19.5,19.5v0c0,10.8-8.7,19.5-19.5,19.5H344'
          />
          <path
            className='st7'
            d='M425.8,289L425.8,289c0-21.5-17.4-39-39-39h-78.8'
          />
          <path
            className='st7'
            d='M425.8,211L425.8,211c0,21.5-17.4,39-39,39h-78.8'
          />
          <line
            className='st7'
            x1='425.8'
            y1='211'
            x2='425.8'
            y2='289'
          />
          <line
            className='st17'
            x1='357.1'
            y1='289'
            x2='357.1'
            y2='225.2'
          />
        </g>
        <g>
          <path
            className='st17'
            d='M296.3,203.7l34.4-34.4c7.6-7.6,19.9-7.6,27.5,0v0c7.6,7.6,7.6,19.9,0,27.5L344,211'
          />
          <path
            className='st7'
            d='M401.9,153.2L401.9,153.2c-15.2-15.2-39.9-15.2-55.1,0l-55.7,55.7'
          />
          <path
            className='st7'
            d='M346.8,98.1L346.8,98.1c15.2,15.2,15.2,39.9,0,55.1l-55.7,55.7'
          />
          <line
            className='st7'
            x1='346.8'
            y1='98.1'
            x2='401.9'
            y2='153.2'
          />
          <line
            className='st17'
            x1='353.3'
            y1='201.8'
            x2='308.2'
            y2='156.7'
          />
        </g>
      </g>
    </svg>
  );
};

export default LoaderSvgIconSecond;
