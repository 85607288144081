import React from 'react';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import { Logo } from './Logo';
import { Environment } from '@react-three/drei';

const CanvasLogo = ({ loaded }) => {
  return (
    <Canvas
      className='canvas'
      camera={{
        position: [0, 0, -4],
        fov: 50,
      }}
      gl={{
        toneMapping: THREE.AdditiveAnimationBlendMode,
      }}
      performance={{ min: 0.5 }}>
      <Logo loaded={loaded} />
      <Environment files='../hdr/night.hdr' />
      <pointLight
        position={[-1.45, 0, -0.75]}
        intensity={30}
        color='#aa3f1f'
      />
    </Canvas>
  );
};

export default CanvasLogo;
