import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const AnimatedNumber = ({ value }) => {
  const numberRef = useRef(null);

  useEffect(() => {
    let endValue;
    let isPercentage = false;
    let isTime = false;

    if (value.includes('%')) {
      isPercentage = true;
      endValue = parseFloat(value.replace(',', '').replace('%', ''));
    } else if (value.includes(':')) {
      isTime = true;
      const timeParts = value.split(':').map((part) => parseInt(part, 10));
      endValue = timeParts[0] * 60 + timeParts[1];
    } else {
      endValue = parseFloat(value.replace(',', ''));
    }

    gsap.fromTo(
      numberRef.current,
      { innerText: 0 },
      {
        innerText: endValue,
        duration: 2.3,
        ease: 'power1.inOut',
        scrollTrigger: {
          trigger: numberRef.current,
          start: 'top 80%',
          end: 'bottom 20%',
          toggleActions: 'play none none none',
        },
        onUpdate: function () {
          if (isPercentage) {
            const currentValue = parseFloat(
              this.targets()[0].innerText
            ).toFixed(1);
            numberRef.current.innerText = `${currentValue}%`;
          } else if (isTime) {
            const totalSeconds = parseInt(this.targets()[0].innerText, 10);
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            numberRef.current.innerText = `${minutes}:${seconds
              .toString()
              .padStart(2, '0')}`;
          } else {
            const currentValue = parseFloat(
              this.targets()[0].innerText
            ).toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
            numberRef.current.innerText = currentValue;
          }
        },
      }
    );
  }, [value]);

  return (
    <div
      className='numbers-number'
      ref={numberRef}>
      {value}
    </div>
  );
};

export default AnimatedNumber;
