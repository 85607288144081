'use client';

import React, { useState, useLayoutEffect, useEffect } from 'react';
import { ReactLenis } from '@studio-freight/react-lenis';
import Header from './components/Header/Header';
import CanvasLogo from './components/Hero/CanvasLogo';
import Hero from './components/Hero/Hero';
import Symbols from './components/Symbols/Symbols';
import Character from './components/Character/Character';
import Draw from './components/Draw/Draw';
import IdentityBig from './components/IdentityBig/IdentityBig';
import Banner from './components/Banner/Banner';
import EndPage from './components/EndPage/EndPage';
import Footer from './components/Footer/Footer';
import LoaderScreen from './components/Loader/LoaderScreen';

function App() {
  const [started, setStarted] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 150);
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <ReactLenis root>
      <div className='App'>
        <LoaderScreen
          started={started}
          setStarted={setStarted}
        />
        <Header />
        <div className='blur blur-canva'></div>
        <div className='blur blur-research'></div>
        <div className='blur blur-symbols'></div>
        <CanvasLogo loaded={started} />
        <Hero />
        <Symbols />
        <Character />
        <Draw />
        <IdentityBig />
        <Banner />
        <EndPage />
        <Footer />
      </div>
    </ReactLenis>
  );
}

export default App;
