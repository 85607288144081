import { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const LineDrawing = ({
  pathSelector,
  initialDashArray,
  finalDashArray,
  triggerSelector,
  start,
  end,
}) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const path = document.querySelector(pathSelector);

    gsap.set(path, { strokeDasharray: initialDashArray });

    ScrollTrigger.create({
      trigger: triggerSelector,
      start,
      end,
      scrub: 1,
      onUpdate: (self) => {
        const progress = self.progress;
        const currentDashArray = gsap.utils.interpolate(
          initialDashArray,
          finalDashArray
        )(progress);
        gsap.set(path, { strokeDasharray: currentDashArray });
      },
    });
  }, [pathSelector, initialDashArray, finalDashArray, triggerSelector, start]);

  return null;
};

export default LineDrawing;
