import React, { useRef, useState, useEffect } from 'react';
import { useGLTF, MeshTransmissionMaterial } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export function Logo({ loaded, ...props }) {
  const { nodes } = useGLTF('../models/logo.gltf');
  const groupRef = useRef();
  const mainGroup = useRef();
  const posYRef = useRef();
  const animationY = useRef();
  const animationX = useRef();
  const animationSecondY = useRef();
  const [positionX, setPositionX] = useState(
    window.innerWidth < 1100 ? 0 : -1.5
  );
  const [positionY, setPositionY] = useState(
    window.innerWidth < 1100 ? 0.5 : 0
  );
  const [scaleGroup, setScaleGroup] = useState(
    window.innerWidth < 1100 ? 0.5 : 1
  );
  const [targetRotation, setTargetRotation] = useState({ x: 0, y: 0 });
  const [currentRotation, setCurrentRotation] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (window.innerWidth > 1100) {
      const handleMouseMove = (event) => {
        setTargetRotation({
          x: -((event.clientY / window.innerHeight) * 2 - 1) * Math.PI * 0.15,
          y: ((event.clientX / window.innerWidth) * 2 - 1) * Math.PI * 0.15,
        });
      };

      window.addEventListener('mousemove', handleMouseMove);

      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, []);

  useEffect(() => {
    if (mainGroup.current) {
      gsap.to(mainGroup.current.rotation, {
        scrollTrigger: {
          trigger: '.hero',
          start: 'bottom bottom',
          end: '200% top',
          scrub: 1,
        },
        x: 0,
        y: Math.PI * 2,
        z: 0,
        immediateRender: false,
      });

      gsap.to(mainGroup.current.position, {
        scrollTrigger: {
          trigger: '.research',
          start: '100% bottom',
          end: 'bottom top',
          scrub: 1,
        },
        x: `${window.innerWidth > 768 ? -8 : 0}`,
        y: -4,
        z: 0,
        immediateRender: false,
      });

      gsap.to(animationX.current.position, {
        scrollTrigger: {
          trigger: '.research',
          start: '190% bottom',
          scrub: 1,
        },
        x: `${window.innerWidth > 768 ? 8 : 0}`,
        y: 0,
        z: 0,
        immediateRender: false,
      });

      gsap.to(animationY.current.position, {
        scrollTrigger: {
          trigger: '.research',
          start: '200% bottom',
          end: '200% top',
          scrub: 1,
        },
        x: 0,
        y: 2.5,
        z: 0,
        immediateRender: false,
      });

      gsap.to(animationSecondY.current.position, {
        scrollTrigger: {
          trigger: '.research',
          start: '380% bottom',
          end: '380% top',
          scrub: 2,
        },
        x: 0,
        y: -5,
        z: 0,
        immediateRender: false,
      });

      gsap.fromTo(
        mainGroup.current.rotation,
        {
          scrollTrigger: {
            trigger: '.symbols-title',
            scrub: 1,
            start: '0 bottom',
            end: 'bottom top',
          },
          x: 0,
          y: Math.PI,
          z: 0,
          immediateRender: false,
        },
        {
          scrollTrigger: {
            trigger: '.symbols-title',
            scrub: 1,
            start: '100% bottom',
            end: '6000% top',
          },
          x: 0,
          y: 30,
          z: 3,
          immediateRender: false,
        }
      );

      gsap.fromTo(
        '.wheel__card',
        {
          scrollTrigger: {
            trigger: '.character',
            scrub: 1,
            start: '0% top',
            end: 'bottom top',
          },
          opacity: 1,
          immediateRender: false,
        },
        {
          scrollTrigger: {
            trigger: '.character',
            scrub: 1,
            start: '-10% top',
            end: 'top top',
          },
          opacity: 0,
          immediateRender: false,
        }
      );

      ScrollTrigger.refresh();
    }
  }, []);

  useEffect(() => {
    if (!loaded) {
      gsap.to(posYRef.current.position, {
        x: 0,
        y: 0,
        z: 0,
        delay: 7.4,
        duration: 1.7,
        ease: 'power4.out',
      });
    }
  }, [loaded]);

  useFrame(() => {
    if (groupRef.current) {
      setCurrentRotation((current) => ({
        x: THREE.MathUtils.lerp(current.x, targetRotation.x, 0.05),
        y: THREE.MathUtils.lerp(current.y, targetRotation.y, 0.05),
      }));

      groupRef.current.rotation.x = currentRotation.x;
      groupRef.current.rotation.y = currentRotation.y;
    }
  });

  return (
    <group ref={animationSecondY}>
      <group ref={animationX}>
        <group ref={animationY}>
          <group
            position-y={-3.5}
            ref={posYRef}>
            <group
              rotation={[0, 0, 0]}
              position={[positionX, positionY, 0]}
              scale={scaleGroup}
              ref={mainGroup}>
              <group
                ref={groupRef}
                {...props}
                dispose={null}>
                <mesh geometry={nodes.Plane001.geometry}>
                  <MeshTransmissionMaterial
                    thickness={0.3}
                    roughness={0.2}
                    transmission={0.7}
                    ior={0.8}
                    chromaticAberration={0.3}
                    backside={false}
                  />
                </mesh>
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload('../models/logo.gltf');

export default Logo;
