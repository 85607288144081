import './style.scss';
import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

let teamData = [
  {
    title: 'Кирило Онацький',
    subtitle: 'Керівник проєкту',
  },
  {
    title: 'Маргарита Гаряжа',
    subtitle: 'Арт-директорка',
  },
  {
    title: 'Дарʼя Маркова',
    subtitle: 'Стратегиня та дослідниця',
  },
  {
    title: 'Микола Ганиш',
    subtitle: 'Головний дизайнер',
  },
  {
    title: 'Марія Василенко',
    subtitle: 'Бренд-стратегиня',
  },
  {
    title: 'Катерина Мокровольська',
    subtitle: 'PR-менеджерка',
  },
  {
    title: 'Євген Швець',
    subtitle: 'Відеомейкер',
  },
  {
    title: 'Дарина Матвійчук',
    subtitle: 'SMM-менеджерка',
  },
  {
    title: 'Марʼян Слабопшицький',
    subtitle: 'Оператор',
  },
  {
    title: 'Володимир Мотричук',
    subtitle: 'Копірайтер',
  },
  {
    title: 'Дарина Бабляк',
    subtitle: 'Асистентка',
  },
  {
    title: 'Ніка Сін',
    subtitle: 'Копірайтерка',
  },
];

let teamDataRightBlock = [
  {
    title: 'Бостан Сергій Іванович',
    subtitle: 'Директор',
  },
  {
    title: 'Сідляр Руслана Василівна',
    subtitle: 'Заступниця директора',
  },
  {
    title: 'Житарюк Ігор Валерійович',
    subtitle: 'Заступник директора',
  },
  {
    title: 'Тодорович Павло Юрійович',
    subtitle: 'Заступник директора',
  },
  {
    title: 'Помогаєва Кристина Олегівна',
    subtitle: 'Начальниця відділу туризму',
  },
];

let address = [
  {
    title: 'Адреса',
    subtitle: 'м.Чернівці, вул.О.Кобилянської, 3',
  },
  {
    title: 'Телефон',
    subtitle: '+380 372 524 870',
  },
  {
    title: 'Email',
    subtitle: 'rozvytok@rada.cv.ua',
  },
];

const EndPage = () => {
  const videoRef = useRef(null);
  const videoVideoRef = useRef(null);
  const playRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 1100) {
      const handleMouseMove = (e) => {
        const rect = videoRef.current.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        gsap.to(playRef.current, {
          x: mouseX,
          y: mouseY,
          duration: 0.7,
          ease: 'power2.out',
        });
      };

      const handleMouseEnter = () => {
        gsap.to(playRef.current, {
          opacity: 1,
          duration: 0.3,
          ease: 'power2.out',
        });
      };

      const handleMouseLeave = () => {
        gsap.to(playRef.current, {
          opacity: 0,
          duration: 0.3,
          ease: 'power2.out',
        });
      };

      const videoElement = videoRef.current;

      videoElement.addEventListener('mousemove', handleMouseMove);
      videoElement.addEventListener('mouseenter', handleMouseEnter);
      videoElement.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        videoElement.removeEventListener('mousemove', handleMouseMove);
        videoElement.removeEventListener('mouseenter', handleMouseEnter);
        videoElement.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []);

  const togglePlay = () => {
    const video = videoVideoRef.current;

    if (video.paused) {
      video.play();
      setIsPlaying(true);
      gsap.to('.play', {
        opacity: 0,
        scale: 0,
      });
    } else {
      video.pause();
      setIsPlaying(false);
      gsap.to('.play', {
        opacity: 1,
        scale: 1,
      });
    }
  };

  return (
    <>
      <section
        className='video'
        ref={videoRef}>
        <video
          src='./img/video/video.mp4'
          loop
          playsInline
          poster='./img/video/prev.jpg'
          ref={videoVideoRef}
          onClick={togglePlay}></video>
        <div
          className='play'
          ref={playRef}
          onClick={togglePlay}>
          <img
            src='./img/video/play.svg'
            alt=''
          />
        </div>
      </section>
      <section
        className='team'
        id='team'>
        <div className='container'>
          <h2>Команда</h2>
          <div className='team__wrapper'>
            <div className='team-block'>
              <h4>
                Команда проєкту <span>«OCB Agency»</span>
              </h4>
              <ul>
                {teamData.map((member, index) => (
                  <li key={index}>
                    <h5>{member.title}</h5>
                    <p>{member.subtitle}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className='team-block'>
              <h4>
                Команда Департаменту соціально-економічного розвитку та
                стратегічного планування{' '}
                <span>«Чернівецької міської ради»</span>
              </h4>
              <ul className='address'>
                {address.map((address, index) => (
                  <li key={index}>
                    <h5>{address.title}</h5>
                    <p>{address.subtitle}</p>
                  </li>
                ))}
                <li></li>
              </ul>
              <ul>
                {teamDataRightBlock.map((member, index) => (
                  <li key={index}>
                    <h5>{member.title}</h5>
                    <p>{member.subtitle}</p>
                  </li>
                ))}
                <li></li>
              </ul>
            </div>
          </div>
          <div className='team-moderator'>
            <h3>
              <span>Модератор проєкту</span> від «GIZ»
            </h3>
            <div>
              <h5>Наталя Мала</h5>
              <p>Модераторка проєкту</p>
            </div>
          </div>
        </div>
      </section>
      <section className='download'>
        <div className='container'>
          <h3>Документи для завантаження</h3>
          <div className='download__wrapper'>
            <a
              className='download-block'
              href='./img/Результати_якісного_дослідження_міста_Чернівці_2024.pdf'
              download>
              <div className='download-text'>
                <img
                  className='download-text__file'
                  src='./img/download.svg'
                  alt=''
                />
                <p>Якісне дослідження сприйняття міста</p>
                <img
                  className='download-text__icon'
                  src='./img/download-svg.svg'
                  alt=''
                />
              </div>
              <div className='download-btn'>
                <div className='download-btn__arrow'>
                  <svg
                    className='first-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                  <svg
                    className='second-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                </div>
              </div>
            </a>
            <a
              className='download-block'
              href='./img/Бренд_та_комунікаційна_стратегія_Чернівці_2024_1.pdf'
              download>
              <div className='download-text'>
                <img
                  className='download-text__file'
                  src='./img/download.svg'
                  alt=''
                />
                <p>Бренд та комунікаційна стратегія міста </p>
                <img
                  className='download-text__icon'
                  src='./img/download-svg.svg'
                  alt=''
                />
              </div>
              <div className='download-btn'>
                <div className='download-btn__arrow'>
                  <svg
                    className='first-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                  <svg
                    className='second-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                </div>
              </div>
            </a>
            <a
              className='download-block'
              href='./img/Результати_аудиту_міста_та_кількісного_дослідження_міста_Чернівці.pdf'
              download>
              <div className='download-text'>
                <img
                  className='download-text__file'
                  src='./img/download.svg'
                  alt=''
                />
                <p>Кількісне дослідження та медіа перцепція</p>
                <img
                  className='download-text__icon'
                  src='./img/download-svg.svg'
                  alt=''
                />
              </div>
              <div className='download-btn'>
                <div className='download-btn__arrow'>
                  <svg
                    className='first-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                  <svg
                    className='second-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                </div>
              </div>
            </a>
            <a
              className='download-block'
              href='./img/GIZ-Сhernivtsi-BrandBook.pdf'
              download>
              <div className='download-text'>
                <img
                  className='download-text__file'
                  src='./img/download.svg'
                  alt=''
                />
                <p>Брендбук міста Чернівці</p>
                <img
                  className='download-text__icon'
                  src='./img/download-svg.svg'
                  alt=''
                />
              </div>
              <div className='download-btn'>
                <div className='download-btn__arrow'>
                  <svg
                    className='first-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                  <svg
                    className='second-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
      <section className='partners'>
        <div className='container'>
          <img
            src='./img/partners/partners.png'
            alt=''
          />
          <p>
            Веб-сайт виготовлений в межах програми міжнародної співпраці
            «EU4Business: відновлення, конкурентоспроможність та
            інтернаціоналізація МСП», що спільно фінансується Європейським
            Союзом та урядом Німеччини і реалізується німецькою федеральною
            компанією Deutsche Gesellschaft für Internationale Zusammenarbeit
            (GIZ) GmbH на замовлення Чернівецької міської ради.
          </p>
          <p>
            Програма спрямована на підтримку економічної стійкості, відновлення
            та зростання України, створення кращих умов для розвитку українських
            малих і середніх підприємств (МСП), а також підтримку інновацій та
            експорту. Детальніше: www.eu4business.org.ua. Зміст публікації є
            виключною відповідальністю OCB Agency і не обов’язково відображає
            позицію Європейського Союзу, уряду Німеччини, GIZ.
          </p>
        </div>
      </section>
      <section className='create'>
        <div className='container'>
          <div className='create__wrapper'>
            <div className='create-block'>
              <h2>Твори з натхненням, живи із задоволенням.</h2>
              <a
                href='https://t.me/kirill_onatskiy'
                target='_blank'
                className='create-block__button'>
                Обговорити проєкт
              </a>
            </div>
            <div className='create-block'>
              <div className='contacts'>
                <h4>Контакти:</h4>
                <ul className='contacts-menu'>
                  <li className='contacts-menu__item'>
                    <a
                      className='contants-link'
                      href='https://t.me/kirill_onatskiy'
                      target='_blank'>
                      <span className='contacts-link__img'>
                        <img
                          src='./img/socials/telegram.svg'
                          alt=''
                        />
                      </span>
                      <p className='contacts-link__text'>
                        t.me/kirill_onatskiy
                      </p>
                    </a>
                  </li>
                  <li className='contacts-menu__item'>
                    <a
                      className='contants-link'
                      href='mailto:hello@ocb.agency'>
                      <div className='contacts-link__img'>
                        <img
                          src='./img/socials/email.svg'
                          alt=''
                        />
                      </div>
                      <p className='contacts-link__text'>hello@ocb.agency</p>
                    </a>
                  </li>
                  <li className='contacts-menu__item'>
                    <a
                      href='https://www.google.com/maps/place/%D1%83%D0%BB.+%D0%9C%D0%B8%D1%85%D0%B0%D0%B9%D0%BB%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F,+12,+%D0%96%D0%B8%D1%82%D0%BE%D0%BC%D0%B8%D1%80,+%D0%96%D0%B8%D1%82%D0%BE%D0%BC%D0%B8%D1%80%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+%D0%A3%D0%BA%D1%80%D0%B0%D0%B8%D0%BD%D0%B0,+10001/@50.2554754,28.6648948,16z/data=!3m1!4b1!4m6!3m5!1s0x472c6496e81f77bf:0xd6929a5ae98aba82!8m2!3d50.2554754!4d28.6648948!16s%2Fg%2F1tfbghb6?entry=ttu'
                      target='_blank'
                      className='contants-link'>
                      <div className='contacts-link__img'>
                        <img
                          src='./img/socials/marker.svg'
                          alt=''
                        />
                      </div>
                      <p className='contacts-link__text'>
                        Mykhailivska 12, Str. Zhytomyr
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className='social'>
                <h4>Соціальні мережі</h4>
                <ul>
                  <li>
                    <a
                      className='social-link'
                      href='https://www.instagram.com/hello.ocb/'
                      target='_blank'>
                      <img
                        src='./img/socials/instagram.svg'
                        alt=''
                      />
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className='social-link'
                      href='#'
                      target='_blank'>
                      <img
                        src='./img/socials/linkedin.svg'
                        alt=''
                      />
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EndPage;
